.iblock {
  display: inline-block;
}
.center {
  text-align: center;
}
.debug {
  outline: 1px dotted red;
}
.debug2 {
  outline: 1px dotted blue;
}
.reset {
  list-style: none;
  padding: 0;
}
.marg {
  margin: 10px;
}
.btn {
  border: 2px solid rgba(255, 255, 255, 0.5);
  position: relative;
  border-radius: 3px;
  padding: .2em .5em;
  cursor: pointer;
}
.btn:hover {
  border-color: white;
  color: white;
}
.js-target {
  position: fixed;
  bottom: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 1s;
  transform: translateY(100%);
}
.js-target::before {
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: ' ';
  transition: .6s;
  position: absolute;
  background-color: #3C3C3C;
}
.js-target > .js-canvas {
  transform: translateY(100%);
  position: absolute;
  transition: .5s;
  width: 100%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.js-target iframe {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .5s;
  visibility: hidden;
  position: relative;
}
.js-target iframe.active {
  visibility: visible;
  opacity: 1;
}
.js-canvas.is-submenu,
.js-canvas.is-iframe {
  overflow: auto;
  display: none;
}
.flip-y {
  transform: rotate(180deg);
}
.arrow-down {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 -2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: .7;
  display: block;
  width: 1em;
  height: 1em;
}
.is-opened {
  display: none;
  margin: 0 !important;
}
.header .js-close {
  visibility: hidden;
}
body.blocked {
  overflow: hidden;
}
body.blocked .js-target {
  transform: translateY(0);
}
body.blocked .js-target::before {
  opacity: 1;
}
body.blocked .js-target > .js-canvas {
  transform: translateY(0);
}
body.blocked.embed .js-target > .js-canvas.is-iframe {
  overflow: hidden;
}
body.blocked.embed .js-target > .js-canvas.is-iframe,
body.blocked.menu .js-target > .js-canvas.is-submenu {
  display: block;
}
@media screen and (min-width: 890px) {
  body.blocked {
    overflow: auto;
  }
  body.blocked.menu .js-target {
    transform: translateY(100%);
  }
  body.blocked.menu .header .js-close {
    visibility: hidden;
  }
}
body.blocked .header .js-close {
  visibility: visible;
}
body.blocked .is-opened {
  display: block;
}
body.blocked .is-closed {
  display: none;
}
body,
html {
  height: 100%;
  margin: 0;
  letter-spacing: 0.02em;
  line-height: 1.42857143;
  font-family: opensans, "Helvetica", Arial;
  color: #d5e3e6;
}
p ~ p {
  margin-top: .6em;
}
img {
  max-width: 100%;
}
* {
  margin: 0;
  box-sizing: border-box;
}
nav.flex .cell {
  margin-right: 10px;
}
nav.flex .cell:last-child {
  margin: 0;
}
.segment .container .flex {
  padding: 10px;
}
.segment .container .flex .flex {
  padding-bottom: 0;
}
.segment .container .iblock {
  margin-right: 20px;
}
.segment .container .iblock:last-child {
  margin: 0;
}
.wrapper {
  height: 100%;
  position: relative;
}
.header {
  background-color: rgba(51, 51, 51, 0.75);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  box-sizing: border-box;
  display: block;
  width: 100%;
  z-index: 2;
}
.header .flex .btn {
  float: right;
  width: 36px;
  height: 36px;
}
.header .item a {
  display: block;
}
.content {
  top: 0;
  height: 320px;
  min-height: 100vh;
  overflow: hidden;
}
.cover {
  background-position: 50% 50%;
  background-size: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 3s;
  opacity: 0;
}
.cover.active {
  opacity: 1;
}
.cover video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
@media (max-aspect-ratio: 16/9) {
  .cover video {
    width: auto;
    height: 100%;
  }
}
@media (min-width: 1500px) {
  .cover video {
    width: 100%;
    height: auto;
  }
}
.item {
  padding: 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.item:last-child {
  border: 0;
}
.item a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
.item a:hover {
  color: #fefffe;
}
.item .sub-list {
  list-style-type: none;
  padding: 0;
}
.item .sub-list a {
  display: block;
  padding: 10px;
  padding-left: .8em;
  border-bottom: 1px solid gray;
}
.item .sub-list a:hover {
  border-color: white;
}
.item.cell {
  position: relative;
}
.item.cell .sub-menu {
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  display: none;
  padding: 0;
  top: 100%;
  left: 0;
  list-style-type: none;
}
.item.cell .sub-menu a {
  border-bottom: 1px solid gray;
  display: block;
  padding: 10px;
}
.item.cell .sub-menu a:hover {
  border-color: white;
}
.item.cell:hover .sub-menu {
  display: block;
}
.is-submenu .item::before {
  content: '❯ ';
}
.is-submenu .item label {
  color: #444444;
  border-radius: 50%;
  position: relative;
  background-color: #d3d3d3;
  float: right;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1;
  text-shadow: 1px 1px 0 #d5e3e6;
}
.is-submenu .item input {
  position: absolute;
  visibility: hidden;
  left: 0;
  top: 0;
}
.is-submenu .item input:checked ~ p,
.is-submenu .item input:hover ~ p {
  display: block;
}
.is-submenu .item p {
  display: none;
}
.rotated:nth-child(1) .banner {
  transform: rotate(-3deg);
}
.rotated:nth-child(2) .banner {
  transform: rotate(4deg);
}
.rotated:nth-child(3) .banner {
  transform: rotate(-2deg);
}
.rotated:nth-child(4) .banner {
  transform: rotate(2deg);
}
.rotated:nth-child(5) .banner {
  transform: rotate(-3deg);
}
.rotated:nth-child(6) .banner {
  transform: rotate(2deg);
}
.box.product {
  position: relative;
}
.box.product img {
  margin: auto;
  display: block;
  max-width: 90%;
  max-height: 200px;
  position: relative;
  margin-bottom: 20px;
  border: 10px solid #fefffe;
  transform: translateX(-5px);
  border-radius: 3px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
}
.box.product p {
  background-color: rgba(60, 60, 60, 0.9);
  transform: translateY(120%);
  border-radius: 3px;
  transition: .4s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  padding: 10px;
  color: #fefffe;
  bottom: 0;
  right: 0;
  left: 0;
}
.box.product h4 {
  opacity: 1;
  transition: .3s;
}
.box.product:hover p {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.box.product:hover h4 {
  opacity: 0;
}
.box.product a {
  text-decoration: none;
  display: block;
  color: #fefffe;
}
.box.product span {
  text-decoration: underline;
  margin-top: 10px;
  display: block;
}
.box .caption {
  color: #333333;
  font-size: 20px;
}
.box .body {
  color: #444444;
}
#menu {
  z-index: -1;
  position: relative;
  background-color: #fefffe;
}
#menu .mouse-icon {
  position: absolute;
  z-index: -1;
  margin: auto;
  right: 0;
  left: 0;
  top: -100px;
}
.segment.padded {
  padding: 10% 2%;
}
.segment .highlight {
  color: #111;
}
.segment .hint {
  border-radius: 3px;
  padding: 10px;
  padding-left: 50px;
  background-color: rgba(255, 255, 255, 0.3);
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDY1IDY1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA2NSA2NTsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0zMi41LDBDMTQuNTgsMCwwLDE0LjU3OSwwLDMyLjVTMTQuNTgsNjUsMzIuNSw2NVM2NSw1MC40MjEsNjUsMzIuNVM1MC40MiwwLDMyLjUsMHogTTMyLjUsNjFDMTYuNzg1LDYxLDQsNDguMjE1LDQsMzIuNSAgICBTMTYuNzg1LDQsMzIuNSw0UzYxLDE2Ljc4NSw2MSwzMi41UzQ4LjIxNSw2MSwzMi41LDYxeiIgZmlsbD0iI0ZGRkZGRiIvPgoJCTxjaXJjbGUgY3g9IjMzLjAxOCIgY3k9IjE5LjU0MSIgcj0iMy4zNDUiIGZpbGw9IiNGRkZGRkYiLz4KCQk8cGF0aCBkPSJNMzIuMTM3LDI4LjM0MmMtMS4xMDQsMC0yLDAuODk2LTIsMnYxN2MwLDEuMTA0LDAuODk2LDIsMiwyczItMC44OTYsMi0ydi0xN0MzNC4xMzcsMjkuMjM3LDMzLjI0MSwyOC4zNDIsMzIuMTM3LDI4LjM0MnogICAgIiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}
.segment .container {
  max-width: 1500px;
  margin: auto;
}
.segment .container.padded {
  padding: 2%;
}
.segment .title {
  font-size: 30px;
  font-weight: 500;
  line-height: 130%;
}
.segment .link {
  transition: .3s;
  text-decoration: none;
}
.segment .link:hover {
  text-decoration: underline;
}
.segment .parr {
  font-size: 16px;
}
.segment.green {
  background-color: #829E3D;
}
.segment.green .subcolor {
  color: #FFCC00;
}
.segment.green .link {
  color: #104C5B;
}
.segment.white {
  background-color: #fefffe;
  color: #3D3D3D;
}
.segment.white .secondary {
  color: #60332f;
}
.segment.white .subcolor,
.segment.white .link {
  color: #4e91a2;
}
.segment.grey {
  background-color: #3D3D3D;
}
.segment.grey .subcolor,
.segment.grey .link {
  color: #FFCC00;
}
.segment.grey .secondary {
  text-decoration: none;
}
.segment.grey .secondary:hover {
  text-decoration: underline;
}
.segment.light-blue {
  background-color: #4e91a2;
}
.segment.light-blue .subcolor,
.segment.light-blue .link {
  color: #60332f;
}
.segment.blue {
  background-color: #104C5B;
}
.segment.blue .link {
  color: #d5e3e6;
}
.segment.blue .link:hover {
  color: #829E3D;
}
.segment.blue .subcolor,
.segment.blue .secondary {
  color: #829E3D;
}
.segment.blue .secondary:hover {
  text-decoration: underline;
}
.blank {
  background-color: #fefffe;
  padding: 20px;
}
.footer {
  background-color: #3D3D3D;
  font-size: 80%;
  padding: 5%;
}
.footer .link {
  color: #d5e3e6;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cell {
  flex: 1 1 auto;
}
.box {
  margin: 10px;
  padding: 10px;
}
.box .logo {
  max-height: 120px;
}
.sm-show,
.md-show,
.lg-show {
  display: none;
}
.col-2,
.col-3,
.col-6 {
  width: 100%;
}
.col-60 {
  width: 60%;
}
@media screen and (min-width: 480px) {
  .sm-show {
    display: block;
  }
  .sm-hide {
    display: none;
  }
  .col-2 {
    width: 50%;
  }
}
@media screen and (min-width: 890px) {
  #info {
    max-width: 80%;
  }
  .col-6 {
    width: 50%;
  }
  .md-hide {
    display: none;
  }
  .md-show {
    display: block;
  }
  .col-2 {
    width: 33.33333333%;
  }
}
@media screen and (min-width: 960px) {
  #info {
    max-width: 70%;
  }
  .col-6 {
    width: 33.33333333%;
  }
  .lg-show {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  #info {
    max-width: 60%;
  }
  .col {
    width: 33.3%;
  }
  .col-6 {
    width: 16.66666667%;
  }
  .col-3 {
    width: 33.33333333%;
  }
}
@media screen and (max-width: 719px) {
  .col-6:nth-child(5),
  .col-6:nth-child(6) {
    width: 50%;
  }
}
.help {
  background-color: #F2ECD6;
  font-family: opensans, "Helvetica", Arial;
  color: #777777;
  font-size: 14px;
}
.tab {
  background-color: rgba(247, 247, 247, 0.5);
  border: 1px solid #d3d3d3;
  display: block;
  margin-right: 2px;
}
.tab.is-active {
  padding-top: 1px;
  transform: translateY(1px);
  border-color: #b3b3b3;
  background-color: #F7F7F7;
  cursor: pointer;
}
.tab.is-active a {
  color: #631D11;
}
.tab a {
  text-decoration: none;
  padding: 8px 12px;
  transition: .3s;
  display: block;
}
.tab a:active {
  color: #631D11;
}
.tab a:focus {
  outline: 1px dotted #631D11;
}
@media screen and (min-width: 33em) {
  .tab {
    border-bottom: 0;
    display: inline-block;
    border-radius: 2px 2px 0 0;
  }
}
.tab-bodies {
  background-color: #F7F7F7;
  border: 1px solid #b3b3b3;
  padding: 1.5em;
}
.tab-body {
  clear: both;
  display: none;
}
.tab-body.is-active {
  display: block;
}
.mouse-icon {
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  height: 50px;
  width: 35px;
}
.drop {
  animation-name: drop;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.wheel {
  background: rgba(255, 255, 255, 0.8);
  position: relative;
  border-radius: 10px;
  width: 3px;
  height: 6px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
@keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}
